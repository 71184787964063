<template>
  <div class="">
    <center-title title="对账明细" :bottom="15"></center-title>
    <el-table :data="billDetail">
      <el-table-column align="center" prop="settle_sn" label="结算批次号">
      </el-table-column>
      <el-table-column align="center" prop="order_sn" label="订单编号">
      </el-table-column>
      <el-table-column align="center" prop="order_amount" label="订单金额">
        <template slot-scope="scope">
          <make-priceformat :price="scope.row.order_amount" :subscriptSize="14" :firstSize="14" :secondSize="14"
            color="#333333"></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="refund_amount" label="退款订单金额">
        <template slot-scope="scope">
          <make-priceformat :price="scope.row.refund_amount" :subscriptSize="14" :firstSize="14" :secondSize="14"
            color="#333333"></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="after_sales_amount" label="售后订单金额">
        <template slot-scope="scope">
          <make-priceformat :price="scope.row.after_sales_amount" :subscriptSize="14" :firstSize="14" :secondSize="14"
            color="#333333"></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="order_complete_time" label="对账时间" width="130px">
      </el-table-column>
    </el-table>
    <div class="row-center pagination mt30">
      <make-pagination @resetList="resetList" :orderObject="orderObject"></make-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      moment,
      billDetail: [],
      orderObject: {},
      page_no: 1,
      page_size: 10,
    };
  },
  computed: {},
  watch: {},
  methods: {
    toUrl() {
      window.open(this.billDetail.bill_img, "_blank");
    },
    getBillDetail() {
      this.$api.getSettlementDetailApi({
        buy_settle_id: this.$route.query.id,
          page_no: this.page_no,
          page_size: this.page_size,
      }).then(res => {
        this.billDetail = res.data.list
        let { lists, count, page_size: size, page_no: page } = res.data;
        this.orderObject = res.data;
        this.orderObject = {
          page,
          size,
          count,
          lists,
        };
      })
    },
    resetList(e) {
      this.page_no = e;
      this.getSettlementList();
    },
  },
  created() {
    // this.detailInfo = JSON.parse(decodeURIComponent(this.$route.query.data));
    this.getBillDetail();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
::v-deep .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>